
import styled from "styled-components";

const StyledWrapper = styled.article`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(100vh - 170px);
    max-width: 100vw;
    flex-direction: column;
    padding: 2rem;

    @media screen and (max-width: 960px) {
        padding: 2rem 0.2rem;
    }
`;

const StyledHeader = styled.header`
  font-family: "Montserrat", sans-serif;
  color: #3B789E;
    font-weight: 200;
    padding: 1rem 2rem;
    font-size: 2rem;

    @media screen and (max-width: 960px) {
        font-size: 1.2rem;
        text-align: center;
        padding: 0.5rem;
    }
`

const StyledBody = styled.body`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 2rem;

    @media screen and (max-width: 960px) {
        font-size: 0.8rem;
        padding: 0.5rem;
        text-align: center;
    }
`


const StyledPara = styled.p`
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.8rem;
    @media screen and (max-width: 960px) {
        font-size: 0.8rem;
        line-height: 1rem;
        text-align: justify;
        text-justify: inter-word;
    }
`



const SponsoredPosts = () => {
  return (
    <StyledWrapper>
            <StyledHeader>Sponsored Posts</StyledHeader>
            <StyledBody>
                <StyledPara>As with most free to use Apps and Websites, ChatRadar displays sponsored content (advertisements) in order to generate revenue and offset the cost of providing the service. We work with Google AdMob to show you content that is relevant to you.</StyledPara>
                <StyledPara>Advertisements cannot be disabled, but you can manage your advertisement preferences by tapping on the blue triangle icon at the top right hand corner of any advert.</StyledPara>
            </StyledBody>
    </StyledWrapper>
  );
};

export default SponsoredPosts;