import React, { useEffect, useState } from 'react';
import styled from "styled-components"

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

const StyledQuote = styled.blockquote`
  display: flex;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  font-style: italic;
  font-size: 1.2em;
  width: 100%;
  padding: 2em;
  opacity: 0.8;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, ${props => props.gradient});
  min-height: 450px;
  text-shadow: 1px 1px 5px #000000;

  @media screen and (max-width: 960px) {
    font-size: 1.2rem;
    min-height: 300px;
  }
`

const gradients = [
    // blue, blue, white
    [
        '#7F7FD5',
        '#86A8E7',
        '#91EAE4'
    ],
    // red, blue
    [
        '#74ebd5',
        '#ACB6E5'
    ],
    // blue grey to blue back to blue grey
    [
        '#06beb6',
        '#48b1bf'
    ],
    // blue, green
    [
        '#4AC29A',
        '#BDFFF3'
    ],
];

const PingText = ({pingId, text}) => {
    const [gradientColours, setGradientColours] = useState('')
    useEffect(() => {
        let gradientIndex = pingId.charCodeAt(0) % 4
        let selectedGradient = gradients[gradientIndex].join(',')
        setGradientColours(selectedGradient)

    }, [pingId]);

    return (
        <StyledWrapper>
            <StyledQuote gradient={gradientColours}>{text}</StyledQuote>
        </StyledWrapper>
    );
}

export default PingText