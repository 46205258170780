import styled from "styled-components";
import { Link } from 'react-router-dom';
import logoInverted from "../../assets/logo-inverted.png"


const StyledWrapper = styled.div`
    width: 100%;
    background-color: #3a789d;
    padding: 0 1.5rem;
`;


const FooterContainer = styled.div`
    display: flex;
    max-width: 1200px;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    min-height: 95px;
    color: white;

    @media screen and (max-width: 960px) {
      flex-direction: column;
      min-height: 165px;
    }
`

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 960px) {
        margin-top: 0.5rem;
        align-items: center;
    }
`;

const TextItem = styled.p`
    line-height: 1.4rem;
    font-size: 0.8rem;

    a {
        color: white;
        text-decoration: none;
        font-weight: bold;
        opacity: 0.8;
    }

    a: hover {
        opacity: 1;
    };

    @media screen and (max-width: 960px) {
        align-items: center;
    }
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: 960px) {
        margin-top: 1rem;
        align-items: center;
        justify-content: center
    }
`;

const Logo = styled.img`
    max-width: 140px;
    margin-left: -10px;
    padding: 0.5rem;
`;

const StyledLink = styled(Link)`
    color: white;
    text-decoration: none;
    font-weight: bold;
    opacity: 0.8;

    :hover {
        opacity: 1;
    }
`;

const Seperator = styled.span`
    margin: 0 0.4rem;
    opacity: 0.2;
`;

const HideMobile = styled.span`
  @media screen and (max-width: 960px) {
    display: none;
  }
`;


const Footer = () => {
  return (
    <StyledWrapper>
        <FooterContainer>
            <LeftColumn>
                <Logo src={logoInverted}/>
                <TextItem>© 2024 <a href="https://www.bitsplash.com">BitSplash Ltd</a></TextItem>
            </LeftColumn>
            <RightColumn>
                <TextItem>Made with love in Birmingham, UK.</TextItem>
                <TextItem>
                    <StyledLink to='/privacy'>Privacy Policy</StyledLink>
                    <Seperator>|</Seperator>
                    <StyledLink to='/terms'>Terms of Service</StyledLink>
                    <HideMobile>
                        <Seperator>|</Seperator>
                        <StyledLink to='/guidelines'>Community Guidelines</StyledLink>
                    </HideMobile>
                </TextItem>
            </RightColumn>
      </FooterContainer>
    </StyledWrapper>
  );
};

export default Footer;
