
import styled from "styled-components";

const StyledWrapper = styled.article`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(100vh - 170px);
    max-width: 100vw;
    flex-direction: column;
    padding: 2rem;

    @media screen and (max-width: 960px) {
        padding: 2rem 0.2rem;
    }
`;

const StyledHeader = styled.header`
  font-family: "Montserrat", sans-serif;
  color: #3B789E;
    font-weight: 200;
    padding: 1rem 2rem;
    font-size: 2rem;

    @media screen and (max-width: 960px) {
        font-size: 1.2rem;
        text-align: center;
        padding: 0.5rem;
    }
`

const StyledBody = styled.body`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 2rem;

    @media screen and (max-width: 960px) {
        font-size: 0.8rem;
        padding: 0.5rem;
        text-align: center;
    }
`

const AgreementDate = styled.p`
    font-style: italic;
`

const SectionHeader = styled.h2`
  font-family: "Montserrat", sans-serif;
  color: #3B789E;
    font-weight: 200;
    font-size: 1.2rem;
    padding-top: 1.8rem;
    padding-bottom: 0.6rem;

    @media screen and (max-width: 960px) {
        font-size: 1rem;
        padding: 0.8rem;
    }
`

const StyledPara = styled.p`
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.8rem;
    @media screen and (max-width: 960px) {
        line-height: 1rem;
        font-size: 0.8rem;
        text-align: justify;
        text-justify: inter-word;
    }
`



const ThirdPartyTerms = () => {
  return (
    <StyledWrapper>
            <StyledHeader>Third Party Terms</StyledHeader>
            <StyledBody>
                <AgreementDate>Last updated 21st Septmber 2021</AgreementDate>
                
                {/* 1 */}
                <SectionHeader>Users of our iOS application are subject to the following terms required by Apple</SectionHeader>
                <StyledPara>To the maximum extent permitted by applicable law, Apple does not have any warranty obligation with respect to the Nextdoor iPhone app. Apple is not responsible for addressing any claims by you or any third party relating to the Nextdoor iPhone app or your possession or use of it, including, but not limited to: (i) product liability claims; (ii) any claim that the Nextdoor iPhone app does not conform to any applicable legal or regulatory requirement; (iii) claims arising under consumer protection or similar legislation; or (iv) any infringement of a third party’s intellectual property rights. Where permitted by law, Apple, its subsidiaries and our other suppliers are third-party beneficiaries of this agreement, but no one else is. Without limiting the foregoing, (1) upon your acceptance of this agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce it against you as a third-party beneficiary, and (2) Apple is not a party to this agreement and is not responsible for the Nextdoor iPhone app or its contents.</StyledPara>
               
            </StyledBody>
    </StyledWrapper>
  );
};

export default ThirdPartyTerms;