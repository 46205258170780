
import styled from "styled-components";
import phoneSlant from "../../assets/phone-slant.png"
import pingBalloons from "../../assets/ping-balloons.png"
import lostDog from "../../assets/lost-dog.png"
import explorePoi from "../../assets/explore-poi.png"
import AppStoreIcon from "../../assets/app-store.png"
import GooglePlayBadge from "../../assets/google-play-badge.png"
import {StyledRail} from "../shared/rail";
import {AppStoreButton} from "../shared/appStoreButton";
var deviceDetect = require('device-detect')();

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

////
//// Section One
////

const SectionOne = styled.div`
  display: flex;
    width: 100%;
    background-color: #409BC9;
    max-height: 470px;
    margin-bottom: 90px;

    @media screen and (max-width: 960px) {
      max-height: 210px;
      margin-bottom: 40px;
    }
`;

const Screenshot = styled.img`
    position: relative;
    top: 50px;
    height: 500px;

    @media screen and (max-width: 960px) {
        height: 220px;
        margin-bottom: 2rem;
    }
`

const Header = styled.h1`
    font-family: "Montserrat", sans-serif;
    color: #ffffff;
    font-size:  2.5rem;
    line-height: 3.5rem;
    font-weight: 400;
    padding: 1rem;
    margin-bottom: 1.5rem;
    text-align: center;
    text-shadow: 2px 2px 5px #49779B;

    @media screen and (max-width: 960px) {
      font-size: 1.5rem;
      line-height: 1.9rem;
      padding: 0.2rem;
      max-width: 200px;
    }
`

const StyledAppStoreContainer = styled.div`
    display: flex;
`

const StyledAppStoreContainerColumn  = styled(StyledAppStoreContainer)`
  flex-direction: column;
`;

const StyledAppStoreIcon = styled.img`
    max-width: 200px;
    padding: 1rem;
`

const HeroInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    max-width: 500px;

    @media screen and (max-width: 960px) {
        margin: 0;
    }
`


////
//// Shared
////
const SectionInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    max-width:450px;

    @media screen and (max-width: 960px) {
        margin: 0;
      margin: 2rem 1rem;
    }
`

const StyledSectionTitle = styled.h2`
    font-family: "Montserrat", sans-serif;
    color: #3B789E;
    width: 100%;
    padding: 1rem;
    font-size: 1.4rem;
    line-height: 1.9rem;
  font-weight: 300;

    @media screen and (max-width: 960px) {
        padding: 0;
        padding-top: 1rem;
        font-size: 1rem;
      text-align: center;
    }
`

const ImgWrapper = styled.div`
    width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

    @media screen and (max-width: 960px) {
      width: auto;
    }
`


const SectionImgHiddenMobile = styled.img`
    width: 280px;

    @media screen and (max-width: 960px) {
      display: none;
    }
`

const ExploreImg = styled.img`
    width: 400px;
    @media screen and (max-width: 960px) {
      width: 260px;
    }
`

const LostDogImg = styled.img`
    width: 60%;
    margin: 2rem;
    @media screen and (max-width: 960px) {
      width: 90%;
    }
`


const StyledSectionP = styled.p`
    padding: 1rem;
    font-size: 1rem;
    color: #565656;

    @media screen and (max-width: 960px) {
        padding: 0;
        padding-top: 1rem;
        font-size: 1rem;
      text-align: center;
    }
`


////
//// Section Two
////

const SectionTwo = styled.div`
    flex: 1 0;
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    @media screen and (max-width: 960px) {
        margin-bottom: auto;
        margin-top: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;


////
//// Section Three
////

const SectionThreeBg = styled.div`
    width: 100%;
    background-color: #FBFBFB;
`;


const SectionThree = styled.div`
    display: flex;
    flex: 1 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 960px) {
        padding-top: 3rem;
        margin-top: auto;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        margin-bottom: auto;
    }
`;


////
//// Section Four
////

const SectionFour = styled.div`
    width: 100%;
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    @media screen and (max-width: 960px) {
        margin-top: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
    }
`;

const StyledVideo = styled.div`
  iframe {
    width: 430px;
    height: 245px;

    @media screen and (max-width: 960px) {
    width: 360px;
    height: 202px;
    }
  }
`;

////
//// Section Five
////

const SectionFive = styled.div`
    display: flex;
    flex: 1 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 960px) {
        padding-top: 3rem;
        margin-top: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: auto;
    }
`;


const StyledAppStoreLink = styled.a`
    text-decoration: none;

  @media screen and (max-width: 960px) {
    
  }
`

const HiddenMobile = styled.div`
  @media screen and (max-width: 960px) {
    display: none;
  }
`

const HiddenDesktop = styled.div`
  @media screen and (min-width: 960px) {
    display: none;
  }
`


const Home = () => {
  return (
      <StyledWrapper>
        <SectionOne>
            <StyledRail>
          <HeroInfo>
              <Header>Discover what's happening nearby.</Header>
              <HiddenMobile>
                  <StyledAppStoreContainer>
                    <StyledAppStoreLink target="_blank" href="https://apps.apple.com/app/apple-store/id1573548404?pt=123206068&ct=chatradar.com:call_to_action&mt=8"><StyledAppStoreIcon src={AppStoreIcon} /></StyledAppStoreLink>
                    <StyledAppStoreLink href='https://play.google.com/store/apps/details?id=com.chatradar.app&utm_campaign=homepage&utm_source=www.chatradar.com&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><StyledAppStoreIcon alt='Get it on Google Play' src={GooglePlayBadge}/></StyledAppStoreLink>
                  </StyledAppStoreContainer>
              </HiddenMobile>
          </HeroInfo>
            <Screenshot src={phoneSlant}/>
        </StyledRail>
        </SectionOne>

      <StyledRail>
          <SectionTwo>
              <ImgWrapper><SectionImgHiddenMobile src={pingBalloons}/></ImgWrapper>
              <SectionInfo>
                  <HiddenDesktop>
                      <AppStoreButton/>
                  </HiddenDesktop>
                  <StyledSectionTitle>Share what's happening nearby.</StyledSectionTitle>
                  <StyledSectionP>ChatRadar is a location based social app that alerts you in real time when people nearby discuss things that you are passionate about, wherever you are.</StyledSectionP>
                  <StyledSectionP>Imagine being instantly alerted when someone nearby discusses topics that interest you. ChatRadar's unique Pings ensure you never miss out on exciting local events, favourite hangout spots, or community happenings. It's like having your finger on the pulse of your neighbourhood, wherever you are.</StyledSectionP>
              </SectionInfo>
          </SectionTwo>
      </StyledRail>

      <SectionThreeBg>
          <StyledRail>
            <SectionThree>
              <SectionInfo>
                  <StyledSectionTitle>New content everywhere you go.</StyledSectionTitle>
                  <StyledSectionP>Customise your radar settings to ensure you're always up-to-date with what's happening nearby. Be it a pop-up market, a spontaneous beach gathering, or a local music event – ChatRadar's notifications keep you informed about events you won't want to miss.</StyledSectionP>
                  <StyledSectionP>Your ChatRadar journey is not confined to your home turf. Whether you're traveling, exploring new cities, or simply out and about, ChatRadar's Pings adapt to your current location. Share your travel experiences, discover new places, and stay connected wherever life takes you.</StyledSectionP>
              </SectionInfo>
                <StyledVideo>
                    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/St-6GxwTeAg?controls=0&rel=0" title="ChatRadar Video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </StyledVideo>
            </SectionThree>
          </StyledRail>
      </SectionThreeBg>

      <StyledRail>
          <SectionFour>
              <ImgWrapper><ExploreImg src={explorePoi}/></ImgWrapper>
              <SectionInfo>
                  <StyledSectionTitle>Explore nearby places.</StyledSectionTitle>
                  <StyledSectionP>One of the best things about ChatRadar is that it helps you discover new and exciting things in your community. Whether you're looking for a new hiking trail, a great coffee shop, or a cool street art mural, ChatRadar makes it easy to find and explore all the hidden gems in your neighbourhood.</StyledSectionP>
                    <StyledSectionP>Send Pings from at your favourite spots and connect with others in your community.</StyledSectionP>
              </SectionInfo>
          </SectionFour>
      </StyledRail>

      <SectionThreeBg>
          <StyledRail>
              <SectionFive>
                <LostDogImg src={lostDog}/>
                  <HiddenDesktop>
                      <AppStoreButton/>
                  </HiddenDesktop>
                  <HiddenMobile>
                      <StyledAppStoreContainerColumn>
                          <StyledAppStoreLink target="_blank" href="https://apps.apple.com/app/apple-store/id1573548404?pt=123206068&ct=chatradar.com:call_to_action&mt=8"><StyledAppStoreIcon src={AppStoreIcon} /></StyledAppStoreLink>
                          <StyledAppStoreLink href='https://play.google.com/store/apps/details?id=com.chatradar.app&utm_campaign=homepage&utm_source=www.chatradar.com&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><StyledAppStoreIcon alt='Get it on Google Play' src={GooglePlayBadge}/></StyledAppStoreLink>
                      </StyledAppStoreContainerColumn>
                  </HiddenMobile>
              </SectionFive>
          </StyledRail>
      </SectionThreeBg>
    </StyledWrapper>
  );
};
export default Home;
