import React, { useEffect, useState } from 'react';
import styled from "styled-components"

const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;

  @media screen and (max-width: 960px) {
    margin-bottom: 20px;
    padding: 0 1rem;
  }
`
const TimeText = styled.p`
  text-align: end;
  font-size: 0.9rem;
  color: #565656;

  @media screen and (max-width: 960px) {
    font-size: 0.7rem;
  }
`


const PingTime = ({text}) => {
    return (
        <StyledWrapper>
            <TimeText>{text}</TimeText>
        </StyledWrapper>
    );
}

export default PingTime;