import styled from "styled-components";
import AppStoreIcon from "../../assets/app-store.png";
import GooglePlayBadge from "../../assets/google-play-badge.png";
var deviceDetect = require('device-detect')();

const StyledAppStoreIcon = styled.img`
    max-width: 200px;
    padding: 1rem;
`

const StyledAppStoreContainer = styled.div`
    display: flex;
`

const StyledAppStoreLink = styled.a`
    text-decoration: none;

  @media screen and (max-width: 960px) {
    
  }
`


const AppStoreButton = () => {
    return (
        <StyledAppStoreContainer>
            { isIphone() ? (
                    <StyledAppStoreLink target="_blank" href="https://apps.apple.com/app/apple-store/id1573548404?pt=123206068&ct=chatradar.com:call_to_action&mt=8"><StyledAppStoreIcon src={AppStoreIcon} /></StyledAppStoreLink>)
                : (<StyledAppStoreLink target="_blank" href='https://play.google.com/store/apps/details?id=com.chatradar.app&utm_campaign=homepage&utm_source=www.chatradar.com&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><StyledAppStoreIcon alt='Get it on Google Play' src={GooglePlayBadge}/></StyledAppStoreLink>)
            }
        </StyledAppStoreContainer>
    )
}

function isIphone() {
    let devices = [
        'iPhone',
        'iPad',
        'iPod',
        'Macintosh',
    ];

    // check if device is iphone using device-detect
    return devices.includes(deviceDetect.device);
}

export {AppStoreButton};