
import styled from "styled-components";
import Navbar from "./topbar/Navbar";
import Footer from "./footer/Footer";

import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>
};


const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled.div`
  display: flex;
  margin: auto;
  padding-top: 64px;
  width: 100%;
  min-height: calc(100vh - 95px); // Footer is 95px
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-start;
  
  @media screen and (max-width: 960px) {
    min-height: calc(100vh - 165px); // Footer is 165px
  }
`;

const PageContainer = (props) => {
    return (
        <StyledWrapper>
            <ScrollToTop>
                <Navbar/>
                <StyledContent>
                    {props.children}
                </StyledContent>
                <Footer />
            </ScrollToTop>
        </StyledWrapper>
    );
};


export default PageContainer;