
import styled from "styled-components";

const StyledWrapper = styled.article`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(100vh - 170px);
    max-width: 100vw;
    flex-direction: column;
    padding: 2rem;

    @media screen and (max-width: 960px) {
        padding: 2rem 0.2rem;
    }
`;

const StyledHeader = styled.header`
  font-family: "Montserrat", sans-serif;
  color: #3B789E;
    font-weight: 200;
    padding: 1rem 2rem;
    font-size: 2rem;

    @media screen and (max-width: 960px) {
        font-size: 1.2rem;
        text-align: center;
        padding: 0.5rem;
    }
`

const StyledBody = styled.body`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 2rem;

    @media screen and (max-width: 960px) {
        font-size: 0.8rem;
        padding: 0.5rem;
        text-align: center;
    }
`

const AgreementDate = styled.p`
    font-style: italic;
    padding-bottom: 2rem;
`

const SectionHeader = styled.h2`
  font-family: "Montserrat", sans-serif;
  color: #3B789E;
    font-weight: 200;
    font-size: 1.2rem;
    padding-top: 1.8rem;
    padding-bottom: 0.6rem;

    @media screen and (max-width: 960px) {
        font-size: 1rem;
        padding: 0.8rem;
    }
`

const SectionSubHeader = styled.h3`
  font-family: "Montserrat", sans-serif;
  color: #3B789E;
    font-weight: 200;
    font-size: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 960px) {
        font-size: 1rem;
        padding: 0.6rem;
    }
`

const StyledPara = styled.p`
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.8rem;
    @media screen and (max-width: 960px) {
        font-size: 0.8rem;
        line-height: 1rem;
        text-align: justify;
        text-justify: inter-word;
    }
`

const HighlightedSection = styled.span`
    color: #46505b;
`


const Indent = styled.div`
   padding 0 1rem;
`



const CommunityGuidelines = () => {
  return (
    <StyledWrapper>
            <StyledHeader>Community Guidelines</StyledHeader>
            <StyledBody>
                <AgreementDate>Effective 21 September, 2021.</AgreementDate>
                
                <StyledPara>These Community Guidelines have been created to help you understand how to act when using ChatRadar and what to expect from others within the app.</StyledPara>
                <StyledPara>We utilise a combination of technology and the human judgment to moderate content. However, we may not always get this right. Sometimes, this process may fail to remove content that violates our Community Guidelines or it may erroneously remove content that is not in voilation of these guidelines.</StyledPara>
                <StyledPara>If you see either of these situations happening, please report this to us, we are committed to doing what is right and continuously learning.</StyledPara>
                <StyledPara>Please remember that something that may be disagreeable to you may not violate our guidelines. Simply disagreeing with a Ping is not a reason to report it.</StyledPara>

                {/* 1 */}
                <SectionHeader>1. DO NOT ENGAGE IN HARFMFUL ACTIVITY.</SectionHeader>
                <StyledPara>We prohibit any activity that could hurt someone. Below is a non-exhaustive list of activities that are considered harmful and are likely to result in post removal and account termination:</StyledPara>
                <Indent>
                    <SectionSubHeader>1.1. THREATS AND PERSONAL SAFETY.</SectionSubHeader>
                    <StyledPara>No threats to physical safety, security or privacy. No posting personal attacks that insults another user or posts that encourage violence towards another/others.</StyledPara>
                    <SectionSubHeader>1.2. BULLYING.</SectionSubHeader>
                    <StyledPara>No posting of content which is intended to shame, intimidate, degrade, sexually harass. Focus on people’s actions rather than their personal characteristics - we don’t tolerate abuse, stalking, threats, trolling or any form of bullying.</StyledPara>
                    <SectionSubHeader>1.3. DOXXING.</SectionSubHeader>
                    <StyledPara>Do not post something that promotes, documents or facilitates the invasion of someones legitimate expectation of their privacy. Never reveal someone else’s personal information.</StyledPara>
                    <SectionSubHeader>1.4. FRAUD / SPAM.</SectionSubHeader>
                    <StyledPara>Content that purposefully deceives or misrepresents in order to exploit and defraud others whether from a robot or human will be deleted. </StyledPara>
                    <SectionSubHeader>1.5. ILLEGAL OR REGULATED GOODS / SERVICES.</SectionSubHeader>
                    <StyledPara>No posting or promoting of links to illegal downloads, selling, soliciting or offering illegal goods or services such as illegal drugs. No promotion of regulated products or services such as gambling, alcohol or pharmaceuticals.</StyledPara>
                    <SectionSubHeader>1.6. ADULT CONTENT.</SectionSubHeader>
                    <StyledPara>No sexually explicit or suggestive content - photos of nudity, sexually explicit or suggestive content.</StyledPara>
                    <SectionSubHeader>1.6. MISINFORMATION.</SectionSubHeader>
                    <StyledPara>No Political/Religious/Vaccines/Medical/Conspiracy posts designed to misinform other users.</StyledPara>
                    <SectionSubHeader>1.7. CHILD EXPLOITATION.</SectionSubHeader>
                    <StyledPara>Don’t cause harm to children. Only parents and legal guardians have the right to post content or images of their children online. Content that violates laws on child protection or exploitation will be removed and reported to local authorities.</StyledPara>
                    <SectionSubHeader>1.8. COPYRIGHT INFRINGEMENT.</SectionSubHeader>
                    <StyledPara>Don’t distribute, receive, transmit or access any content that is in breach of intellectual property right including copyright and trademark.</StyledPara>
                    <SectionSubHeader>1.9. EXTREMIST CONTENT.</SectionSubHeader>
                    <StyledPara>No content that encourages, incites, promotes or celebrates acts of terrorism or content that supports terrorists or extremist organisations.</StyledPara>
                    <SectionSubHeader>1.10. SELF HARM.</SectionSubHeader>
                    <StyledPara>No content that encourages, promotes or glorifies suicide or self harm.</StyledPara>
                    <SectionSubHeader>1.11. GRAPHIC CONTENT.</SectionSubHeader>
                    <StyledPara>No content that is unecessarily graphic such as gore and mutilation.</StyledPara>
                </Indent>

                <StyledPara>We are committed to removing any content (pings, profile photos) or behavior that violate this policy. Furthermore, users repeatedly engaging in this behavior will be subject to permanent removal from ChatRadar.</StyledPara>


                {/* 2 */}
                <SectionHeader>2. DO NOT DISCRIMINATE.</SectionHeader>
                <StyledPara>We do not tolerate racism, hateful language or conduct, bullying, targeted attacks or discrimination of any kind.</StyledPara>
                <Indent>
                    <SectionSubHeader>2.1. NO RACISM, DISCRIMINATION OR HATE SPEECH</SectionSubHeader>
                    <StyledPara>If you see a ChatRadar Ping that you believe is discriminatory or racist in nature, please follow these steps to report it. If you see a racist post or comment from a user whose behaviour is repeatedly or egregiously discriminatory or racist, please report the individual.</StyledPara>
                    <StyledPara>We are committed to removing any content (pings, profile photos) or racist behavior that violate this policy. Furthermore, users repeatedly engaging in this behavior will be subject to permanent removal from ChatRadar.</StyledPara>
                
                    <SectionSubHeader>2.2. SUPPORT FOR EQUALITY</SectionSubHeader>
                    <StyledPara><HighlightedSection>a. Racial equality.</HighlightedSection> Racism has no place at ChatRadar.</StyledPara>
                    <StyledPara><HighlightedSection>b. LGBTQIA+ equality.</HighlightedSection> Homophobia, biphobia, transphobia, and any other mistreatment, aggression or unkindness shown to people on the basis of their gender and/or sexual orientation is expressly prohibited.</StyledPara>
                </Indent>

                 {/* 3 */}
                 <SectionHeader>3. USE YOUR TRUE IDENTITY.</SectionHeader>
                <StyledPara>Impersonating other people or organisations or pretending to be someone or something you’re not is strictly prohibited.</StyledPara>
                <Indent>
                    <SectionSubHeader>3.1. YOUR IDENTITY</SectionSubHeader>
                    <StyledPara>Our Member Agreement requires that every member use their legal name when signing up.</StyledPara>
                    <SectionSubHeader>3.2. YOUR PROFILE</SectionSubHeader>
                    <StyledPara>Your profile photo must be representative (suitable for a general audience). Not offensive, intimidating or promotional. You must also have rights to use the image</StyledPara>
                </Indent>

            </StyledBody>
    </StyledWrapper>
  );
};

export default CommunityGuidelines;