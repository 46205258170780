import React, { useEffect, useState } from 'react';
import styled from "styled-components"

const StyledWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 15px;
  width: 100%;
  
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;

  @media screen and (max-width: 960px) {
    height: 50px;
    margin-bottom: 20px;
    padding: 0 1rem;
  }
`

const ActionIcon = styled.div`
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  color: #3A779D;
  font-size: 22px;
  padding: 0 0.5rem;

  @media screen and (max-width: 960px) {
    font-size: 19px;
    padding: 0 0.4rem;
  }
`

const ActionIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  border-right: 1px solid #e6e6e6;
`

const CommentText = styled.div`
  text-align: end;
  color: #565656;
  font-size: 0.8rem;

  @media screen and (max-width: 960px) {
    font-size: 0.7rem;
  }
`


const PingActions = ({likeCount}) => {
    return (
        <StyledWrapper>
            <ActionIconWrapper>
                <ActionIcon onClick={console.log('heart clicked')}>
                    <i className={'far fa-heart'} />
                </ActionIcon>
                <ActionIcon onClick={console.log('comment clicked')}>
                    <i className={'far fa-comment'} />
                </ActionIcon>
            </ActionIconWrapper>
            <CommentText>{likesToString(likeCount)}</CommentText>
        </StyledWrapper>
    );
}

function likesToString(likeCount) {
    if (likeCount > 0) {
        return likeCount + ((likeCount === 1) ? ' person likes' : ' people like') + ' this';
    } else {
        return '';
    }
}

export default PingActions;