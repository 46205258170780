
import styled from "styled-components";

const StyledWrapper = styled.article`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(100vh - 170px);
    max-width: 100vw;
    flex-direction: column;
    padding: 2rem;

    @media screen and (max-width: 960px) {
        padding: 2rem 0.2rem;
    }
`;

const StyledHeader = styled.header`
  font-family: "Montserrat", sans-serif;
  color: #3B789E;
    font-weight: 200;
    padding: 1rem 2rem;
    font-size: 2rem;

    @media screen and (max-width: 960px) {
        font-size: 1.2rem;
        text-align: center;
        padding: 0.5rem;
    }
`

const StyledBody = styled.body`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 2rem;

    @media screen and (max-width: 960px) {
        font-size: 0.8rem;
        padding: 0.5rem;
        text-align: center;
    }
`

const SectionHeader = styled.h2`
  font-family: "Montserrat", sans-serif;
  color: #3B789E;
    font-weight: 200;
    font-size: 1.2rem;
    padding-top: 1.8rem;
    padding-bottom: 0.6rem;

    @media screen and (max-width: 960px) {
        font-size: 1rem;
        padding: 0.8rem;
    }
`

const StyledPara = styled.p`
    font-size: 1rem;
    margin-bottom: 0.8rem;
    line-height: 1.3rem;
    @media screen and (max-width: 960px) {
        font-size: 0.8rem;
        line-height: 1rem;
        text-align: justify;
        text-justify: inter-word;
    }
`

const StyledExternalLink = styled.a`
    color: #46505b;
`

const TrademarkPolicy = () => {
  return (
    <StyledWrapper>
            <StyledHeader>Trademark and Copyright Policy</StyledHeader>
            <StyledBody>
                <SectionHeader>Copyright Infringement</SectionHeader>
                <StyledPara>If you believe in good faith that Third Party Content on the ChatRadar platform infringes your copyright rights, you may send ChatRadar a takedown notice requesting that the Third Party Content be removed.  ChatRadar has also designated an agent to receive notices of claimed copyright infringement.  Please note ChatRadar will only remove Third Party Content that infringes your copyright rights upon receiving a valid and fully completed takedown as described below.</StyledPara>
               
                <SectionHeader>Trademark Infringement</SectionHeader>
                <StyledPara>ChatRadar is not in a position to mediate trademark disputes between third parties or act on reports of trademark infringement that require an in-depth trademark analysis or dispute off the ChatRadar platform.  In these types of situations, rather than submitting a notice of trademark infringement to ChatRadar, we suggest that you reach out directly to the third party you believe is infringing your trademark rights or seek resolution of the dispute in court or through other judicial means.  If you want to provide ChatRadar with a notice of trademark infringement, ChatRadar will perform a limited investigation of reasonable complaints and may remove Third Party Content only in clear cases of trademark infringement.</StyledPara>

                <SectionHeader>Takedown Notice Procedure</SectionHeader>
                <StyledPara>Your takedown notice must include the following information in order for ChatRadar to take action:</StyledPara>
                <StyledPara>1. An electronic or physical signature of the person authorised to act on behalf of the owner of the copyright/trademark interest;</StyledPara>
                <StyledPara>2. A description of the copyrighted works or trademarks that you claim have been infringed, including registration number and registration office if applicable;</StyledPara>
                <StyledPara>3. A description specifying the location on the ChatRadar platform of the material that you claim is infringing (i.e. URL address);</StyledPara>
                <StyledPara>4. Your email address, mailing address and/or telephone number;</StyledPara>
                <StyledPara>5. A statement by you that you have a good faith belief that the use of the copyright or trademark on the ChatRadar platform is not authorised by the owner of the copyright or trademark, its agent, or the law; and</StyledPara>
                <StyledPara>6. A statement by you, made under penalty of perjury, that the information in your notice is accurate and that you are the copyright/trademark owner or authorised to act on the copyright or trademark owner’s behalf.</StyledPara>

                <StyledPara>Please submit your takedown notice to ChatRadar’s designated agent via email (<StyledExternalLink href="mailto:copyright@chatradar.com">copyright@chatradar.com</StyledExternalLink>) or post as follows:</StyledPara>
                <StyledPara>BitSplash Ltd, 55 Colmore Row, Birmingham, UK B3 2AA</StyledPara>
            </StyledBody>
    </StyledWrapper>
  );
};

export default TrademarkPolicy;