import styled from "styled-components";
import React, {useEffect, useState} from "react";
import axios from "axios";
import PingComponent from "../ping/ping"
import { useParams } from "react-router-dom";
import {AppStoreButton} from "../shared/appStoreButton";
import {NotFound} from "../shared/notFound";
import {CircularProgressIndicator} from "../shared/loader";

const StyledWrapper = styled.article`
    display: flex;
    justify-content: flex-start;
    padding: 0 2rem;
    flex-direction: column;
    margin-bottom: auto;
    align-self: flex-start;

    @media screen and (max-width: 960px) {
        padding: 0.5rem 0;
    }
`;

const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;

    @media screen and (max-width: 960px) {
        font-size: 0.8rem;
        padding: 0;
        text-align: center;
    }
`


const AppLinkWrapper = styled.div.attrs((props: {bottomBorder: Boolean}) => props)`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    border-bottom: ${(props) => props.bottomBorder ? "1px solid #e0e0e0" : "none"}; 
    width: 100%;
  
    @media screen and (max-width: 960px) {
      font-size: 0.8rem;
      text-align: center;
    }
`

const StyledHeader = styled.h1`
  font-family: 'Pacifico', cursive;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  color: #3C7AA1;

  @media screen and (max-width: 960px) {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
  }
`

const LoadingMessage = styled.div`
  display: flex;
    justify-content: center;
  align-items: center;
  min-height: 80vh;
`

const StreamList = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
`

const PingPage = () => {
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(true)
    const [ping, setPing] = useState([]);
    const [nearbyPing, setNearbyPing] = useState([]);
    const { hash } = useParams();

    useEffect(() => {
        axios.get('/api/p/' + hash).then(function(response){
            if (response.data.ping) {
                setPing(response.data.ping)
                setNearbyPing(response.data.nearby)
                setLoading(false)
                setError(null)
            }
        }).catch(function(error){
            console.error(error)
            setError(error)
            setLoading(false)
        })
    }, [hash]);


    // Loading
    if (loading) {
        return (
            <StyledWrapper>
                <CircularProgressIndicator />
            </StyledWrapper>
        )
    }

    // Errored
    if (!loading && error) {
        return (
            <StyledWrapper>
                <NotFound/>
            </StyledWrapper>
        )
    }


    return (
        <StyledWrapper>
            <StyledHeader>View Ping</StyledHeader>
            <StyledBody>
                <StreamList>
                    <PingComponent ping={ping} showMap={true} />

                    <AppLinkWrapper bottomBorder={nearbyPing && nearbyPing.length > 0}>
                        <p>Get the app</p>
                        <AppStoreButton/>
                    </AppLinkWrapper>

                    { nearbyPing && nearbyPing.length > 0 ? <StyledHeader>Related Pings</StyledHeader> : null }
                    { nearbyPing && nearbyPing.length > 0 ? nearbyPing.map((ping, index) => {
                            return <PingComponent ping={ping} showMap={false} />
                        }) : null }

                  </StreamList>
            </StyledBody>
        </StyledWrapper>
    );
};

export default PingPage;