import styled from "styled-components";

const StyledRail = styled.div`
  display: flex;
  flex: 1 0;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 1200px;
  width: 95%;

  @media screen and (max-width: 960px) {
    min-width: auto;
  }
  
`;

export {StyledRail};