import Chatradar from "./chatradar/components/Wrapper";
import "./App.css"

function App() {
  return (
    <Chatradar/>
  );
}

export default App;
