import styled from "styled-components";
import { useForm } from "react-hook-form";
import React, {useState} from "react";

import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";


const StyledWrapper = styled.article`
    display: flex;
    justify-content: flex-start;
    padding: 0 2rem;
    flex-direction: column;
    align-self: center;
  align-items: center;

    @media screen and (max-width: 960px) {
        padding: 0.5rem 0;
    }
`;

const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0;

    @media screen and (max-width: 960px) {
        font-size: 0.8rem;
        padding: 0;
        text-align: center;
    }
  
  p {
    margin: 0.5rem 0;
    width: 50%;
    text-align: justify-all;
    @media screen and (max-width: 960px) {
        width: 100%;
      padding: 0 1rem;
    }
  }
`


const StyledHeader = styled.h1`
  font-family: 'Pacifico', cursive;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #3C7AA1;

  @media screen and (max-width: 960px) {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
  }
`

const StyledForm = styled.form`
  width: 50%;
  padding: 0.5rem 0;

  @media screen and (max-width: 960px) {
    width: 90%;
    font-size: 0.8rem;
    padding: 0;
  }
`

const StyledInput = styled.input`
  width: 100%;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  height: 2rem;
  padding: 0 0.4rem;
`

const StyledButton = styled.button`
  width: 100%;
  border: none;
  border-radius: 5px;
  height: 2rem;
  padding: 0 0.4rem;
  background: #ff001a;
  font-weight: 500;
  color: white;
  cursor: pointer;
`

const StyledInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
`

const StyledCaptcha = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.5rem 0;
`

const StyledError = styled.p`
  color: red;
  padding: 1rem;
  font-style: italic;
`

const DeleteAccount = () => {

    const { register, handleSubmit } = useForm();
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const recaptchaRef = React.createRef();
    const handleRegistration = function (data) {
        const recaptchaValue = recaptchaRef.current.getValue();

        if (recaptchaValue === "")  {
            setError("Please validate recaptcha");
            return;
        }

        setLoading(true)
        const formData = {...data, recaptcha: recaptchaValue};
        axios.post('/api/delete-account', formData).then(function(response){
            setSubmitted(true)
            setLoading(false)
            setError(null)
            setSubmitted(true)
        }).catch(function(error){
            setError("There was an error submitting your request. Please try again later.")
            setLoading(false)
        })
    };

    if (submitted) {
        return (
            <StyledWrapper>
                <StyledBody>
                    <p>We have received your request to delete your account.</p>
                    <p>We will contact you via email to confirm this request, and once confirmed, we will begin the process of removing your data from our systems. </p>
                    <p>This process typically takes 72 hours to complete.</p>
                </StyledBody>
            </StyledWrapper>
        )
    }

    return (
        <StyledWrapper>
            <StyledHeader>Delete Account</StyledHeader>
            <StyledBody>
                <p>Please complete the form below if you wish to close your ChatRadar account.</p>
                <p>This will send ChatRadar a request to permanently delete your account, including any associated data such as Pings. </p>
                <p><strong>We will contact you via email to confirm this request</strong>, and once confirmed, we will begin the process of removing your data from our systems. </p>
                <p>This process typically takes 72 hours to complete.</p>
            </StyledBody>
            { (error) ? (<StyledError>{error}</StyledError>) : (<div/>)}
            <StyledForm onSubmit={handleSubmit(handleRegistration)}>
                <StyledInputGroup>
                    <StyledInput placeholder="Username (if known)" name="username" {...register('username')} />
                </StyledInputGroup>
                <StyledInputGroup>
                    <StyledInput placeholder="Email address" type="email" name="email" {...register('email')} />
                </StyledInputGroup>
                <StyledCaptcha>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LesW5MmAAAAADaNISO0PyvZTWxs97DMlZQEIK95"
                />
                </StyledCaptcha>
            <StyledInputGroup>
                { (loading) ? (<div/>) : (<StyledButton>Send delete account request</StyledButton>) }
            </StyledInputGroup>
            </StyledForm>
        </StyledWrapper>
    );
};

export default DeleteAccount;