import React from "react";
import styled from "styled-components"
import PingText from "./pingText";
import PingTime from "./pingTime";
import PingActions from "./pingActions";
import chatRadarIcon from "../../assets/icon.png"

const StyledWrapper = styled.div`
  display: flex;
  flex: 1 0;
  width: 800px;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    min-width: auto;
    width: 100vw;
  }
`

const PingTextRegular = styled.p`
  width: 100%;
  line-height: 1;
  text-align: start;
  margin-bottom: 1.2rem;
  padding: 0 0.8rem;
  color: #565656;
`

const StyledImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 800px;

  @media screen and (max-width: 960px) {
    max-height: 500px;
  }
`

const StyledImgLink = styled.a`
  width: 100%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-decoration: none;
  color: white;

  :link {
    color: white;
  }

  /* visited link */
  :visited {
    color: white;
  }

  /* mouse over link */
  :hover {
    color: white;
  }

  /* selected link */
  :active {
    color: white;
  }
  
`

const PingBottomWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 30px;
  padding: 0 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e6ecf0;
  
  @media screen and (max-width: 960px) {
    margin-top: 20px;
    justify-content: space-around;
    padding: 0;
    padding-bottom: 2rem;
  }
`

const ProfileImg = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid #e6e6e6;

  @media screen and (max-width: 960px) {
    height: 60px;
    width: 60px;
  }
  
`

const StyledMapHeader = styled.p`
  color: #46505b;
  margin-top: 10px;
  text-align: center;
`

const MapsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 100px;
`

const ProfileUsername = styled.p`
  text-align: center;
  font-weight: 900;
  font-size: 16px;
  color: #434950;
  margin-top: 18px;

  @media screen and (max-width: 960px) {
    font-size: 13px;
    margin-top: 10px;
  }
`

const StyledMap = styled.img`
  height: 150px;
  width: 300px;
  border-radius: 10px;

  @media screen and (max-width: 960px) {
    height: 80px;
    width: 160px;
  }
`

const apiKey = 'AIzaSyCoKzRmYs4O3h2Lg9wf_1bFmR8smjthdbw'

const PingComponent = ({ping, showMap}) => {

    console.log(ping)

    // if (imageURL === '') {
    //     return <PingText pingId={ping.id} text={ping.text} />
    // }



    let mapUrl = 'https://maps.googleapis.com/maps/api/staticmap?key=' + apiKey + '&center=' + ping.lat + ',' + ping.long + '&scale=2&size=200x100&zoom=12';

    return (
        <StyledWrapper>
            <PingTime text={timestampToString(ping.created_at)}/>
            { ping.text ? (!ping.media || ping.media.isEmpty ? (
                    <StyledImgLink href={'/p/' + ping.web_link_id}><PingText pingId={ping.id ? ping.id : ping.web_link_id} text={ping.text} /></StyledImgLink>)
                : (<PingTextRegular>{ping.text}</PingTextRegular>)) : null
            }
            { !ping.media || ping.media.isEmpty ? (null)
                : ( <StyledImgLink href={'/p/' + ping.web_link_id}><StyledImg src={ping.media[0].content_url} /></StyledImgLink>)
            }

            <PingActions likeCount={ping.like_count} />

            { showMap ? (
                <PingBottomWrapper>
                    <ProfileWrapper>
                        <ProfileImg src={chatRadarIcon}/>
                        <ProfileUsername>ChatRadar User</ProfileUsername>
                    </ProfileWrapper>
                    <MapsWrapper>
                        <StyledMap src={mapUrl} />
                        <StyledMapHeader>Ping Region</StyledMapHeader>
                    </MapsWrapper>
                </PingBottomWrapper>
                ) : (null)
            }
        </StyledWrapper>
    );
}


// function that takes in a timestamp and returns a string representation of how long ago the time was
function timestampToString(timestamp) {
    let time = new Date(timestamp * 1000);
    let now = new Date();
    let diff = now - time

    let years = Math.floor(diff / 31536000000);
    let months = Math.floor(diff / 1000 / 60 / 60 / 24 / 30);
    let weeks = Math.floor(diff / 1000 / 60 / 60 / 24 / 7);
    let seconds = Math.floor(diff / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    if (years > 0) {
        return years + ((years === 1) ? ' year ago' : ' years ago')
    } else if (months > 0) {
        return months + ((months === 1) ? ' month ago' : ' months ago')
    } else if (weeks > 0) {
        return weeks + ((weeks === 1) ? ' week ago' : ' weeks ago')
    } else if (days > 0) {
        return days + ((days === 1) ? ' day ago' : ' days ago');
    } else if (hours > 0) {
        return hours + ((hours === 1) ? ' hour ago' : ' hours ago');
    } else if (minutes > 0) {
        return minutes + ((minutes === 1) ? ' minute ago' : ' minutes ago');
    } else {
        return 'just now';
    }
}

export default PingComponent