import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/logo.png'


const NavMenu = styled.ul.attrs((props: {active: boolean}) => props)`
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;

  @media screen and (max-width: 960px) {
    transition: all 500ms ease;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    height: calc(100vh - 64px);
    position: absolute;
    top: 64px;
    left: ${props => props.active ? '0' : '-200vw'};
    background: ${props => props.active ? 'white' : 'transparent'};
  }
`

const Logo = styled.img`
  justify-self: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  height: 30px;
`

const StyledNav = styled.nav`
  width: 100%;
  background: white;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 1rem;
  margin: auto;

  max-width: 1200px;

  @media screen and (max-width: 960px) {
    padding: 0;
  }
`

const MenuIcon = styled.div`
display: none;

@media screen and (max-width: 960px) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  margin-right: 20px;
  cursor: pointer;
  height: 64px;
  color: #409BC9;
}
`

const NavItem = styled.li`
  display: flex;
  align-items: center;
  height: 64px;
`

const StyledLink = styled(Link)`

color: #3B789E;
text-decoration: none;
padding: 0.4rem 0.5rem;
font-size: 0.9rem;

:hover {
  color: #409BC9;
}

@media screen and (max-width: 960px) {
  text-align: left;
  padding: 1.5rem;
  width: 100%;
  display: table;
  font-size: 1.2rem;
  line-height: 1.5rem;

  :hover {
    background-color: #46505b;
    color: white;
    border-radius: 0;
  }
}

`


const StyledWrapper = styled.nav`
  position: fixed;
  width: 100vw;
  border-bottom: 1px solid #f5f5f5;
  background-color: #ffffff;
    z-index: 200;
`

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <StyledWrapper>
      <StyledNav>

        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <Logo src={logo} alt="ChatRadar" />
        </Link>

        <MenuIcon onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </MenuIcon>

        <NavMenu active={click}>
        <NavItem>
            <StyledLink to='/terms' onClick={closeMobileMenu}>
              Terms of Service
            </StyledLink>
          </NavItem>
          <NavItem>
            <StyledLink to='/privacy' onClick={closeMobileMenu}>
              Privacy Policy
            </StyledLink>
          </NavItem>
          <NavItem>
            <StyledLink to='/guidelines' onClick={closeMobileMenu}>
              Community Guidelines
            </StyledLink>
          </NavItem>
        </NavMenu>
      </StyledNav>
    </StyledWrapper>
  );
}

export default Navbar;
