
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledWrapper = styled.article`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(100vh - 170px);
    max-width: 100vw;
    flex-direction: column;
    padding: 2rem;

    @media screen and (max-width: 960px) {
        padding: 2rem 0.2rem;
    }
`;

const StyledHeader = styled.header`
  font-family: "Montserrat", sans-serif;
  color: #3B789E;
    font-weight: 200;
    padding: 1rem 2rem;
    font-size: 2rem;

    @media screen and (max-width: 960px) {
        font-size: 1.2rem;
        text-align: center;
        padding: 0.5rem;
    }
`

const StyledBody = styled.body`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 2rem;

    @media screen and (max-width: 960px) {
        font-size: 0.8rem;
        padding: 0.5rem;
        text-align: center;
    }
`

const AgreementDate = styled.p`
    font-style: italic;
`

const SectionHeader = styled.h2`
  font-family: "Montserrat", sans-serif;
  color: #3B789E;
    font-weight: 200;
    font-size: 1.2rem;
    padding-top: 1.8rem;
    padding-bottom: 0.6rem;

    @media screen and (max-width: 960px) {
        font-size: 1rem;
        padding: 0.8rem;
    }
`

const SectionSubHeader = styled.h3`
  font-family: "Montserrat", sans-serif;
  color: #3B789E;
    font-weight: 200;
    font-size: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 960px) {
        font-size: 1rem;
        padding: 0.6rem;
    }
`


const StyledPara = styled.p`
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.8rem;
    @media screen and (max-width: 960px) {
        line-height: 1rem;
        font-size: 0.8rem;
        text-align: justify;
        text-justify: inter-word;
    }
`

const Highlighted = styled.span`
    color: #46505b;
    font-weight: 600;
`

const HighlightedSection = styled.span`
    color: #46505b;
`


const StyledLink = styled(Link)`
    color: #46505b;
    text-decoration: none;
`

const StyledExternalLink = styled.a`
    color: #46505b;
`





const PrivacyPolicy = () => {
  return (
    <StyledWrapper>
            <StyledHeader>Privacy Policy</StyledHeader>
            <StyledBody>
                <AgreementDate>Effective 21 September, 2021.</AgreementDate>
                
                {/* 1 */}
                <SectionHeader>1. OUR SERVICES.</SectionHeader>
                <StyledPara>This document outlines our “<Highlighted>Privacy Policy</Highlighted>”, which explains our privacy practices and how we handle your personal information collected through ChatRadar, as well as through social media, our marketing activities and other activities described in this Privacy Policy. This Privacy Policy also explains how we use and share such information and the choices you have.</StyledPara>
                <StyledPara>We use “<Highlighted>ChatRadar</Highlighted>” to refer to the ChatRadar websites and our iOS app (also referred to as our “<Highlighted>Services</Highlighted>”). We also use “<Highlighted>ChatRadar</Highlighted>,” “<Highlighted>we</Highlighted>,” “<Highlighted>us</Highlighted>,” and “<Highlighted>our</Highlighted>” to refer to BitSplash LTD (the company operating the Services) as described in the <StyledLink to='/terms'>Terms of Service</StyledLink>.</StyledPara>
                <StyledPara>Unless otherwise indicated, this Privacy Policy applies to all of our Services in all geographies in which ChatRadar offers its Services. Capitalized words used but not defined have the meanings described in the <StyledLink to='/terms'>Terms of Service</StyledLink>.</StyledPara>

                {/* 2 */}
                <SectionHeader>2. WHAT INFOMRATION IS COLLECTED?</SectionHeader>
                 {/* 2.1 */}
                <SectionSubHeader>2.1. FROM YOU.</SectionSubHeader>
                <StyledPara>We may collect personal information from you, through technology that automatically collects information from your browser or device, and from third party sources. See below for examples. We may also collect personal information that is not specifically listed below, which we use consistent with this Privacy Policy or as otherwise described at the time of collection.</StyledPara>
                <StyledPara><HighlightedSection>- Your contact details.</HighlightedSection> When you sign up for ChatRadar, you are asked to provide your name, phone number, email address and similar information.</StyledPara>
                <StyledPara><HighlightedSection>- Profile information.</HighlightedSection> You may share information about yourself in your profile, including your interests, a picture, and biographical information.</StyledPara>
                <StyledPara><HighlightedSection>- Posts, interests and location.</HighlightedSection> You may post a “<Highlighted>Ping</Highlighted>” on ChatRadar. Pings may include, but are not limited to text, photos, or video, your current location, and anything else you want to share.</StyledPara>
                <StyledPara><HighlightedSection>- Geolocation.</HighlightedSection> The Services require permission from you to access your device’s precise geolocation. We use this data to show content around you and help others discover your content. The precise location of a Ping is <StyledExternalLink href="https://en.wikipedia.org/wiki/Location_obfuscation">obfuscated</StyledExternalLink>, but the broad location, or nearest point of interest of a Ping will be visible to other Members. Additionally, we use your location to personalise our Services, including by adding information to a map, and helping us show you more relevant ads. </StyledPara>
                <StyledPara><HighlightedSection>- Messages and email data.</HighlightedSection> We collect information about you when you send, receive, or engage with messages, emails or physical mail in connection with our Services, including whether you have opened or acted on those messages.</StyledPara>
                <StyledPara><HighlightedSection>- Photos and videos.</HighlightedSection> You may choose to grant the Services permission to collect images, video and other information from your device’s camera and photos, such as when you include images and video in your posts. These files may include metadata indicating how, when, where and by whom the files were created and how they are formatted. Metadata can make your files more searchable and interactive but it may be accessible to other Members or anyone else who can access the files.</StyledPara>
               
               {/* 2.2 */}
               <SectionSubHeader>2.1. FROM YOUR DEVICE.</SectionSubHeader>
               <StyledPara><HighlightedSection>- Device data,</HighlightedSection> such as your device’s manufacturer and model, operating system and version, screen resolution, RAM and disk size, CPU usage, unique identifiers (including identifiers used for advertising purposes), crash data (if applicable), what device settings you’ve enabled, carrier, radio/network information (e.g., WiFi, LTE, 3G), and general location information such as city, state or geographic area. Your device and software settings affect what information your device makes available to us.</StyledPara>
               <StyledPara><HighlightedSection>- Online activity data,</HighlightedSection> such as the date and time you visited, duration of access, the browser or app version you used (and its settings), the URLs you come from and go to, and how you used ChatRadar and interacted with our emails and other messages (for example, how you navigated and which links you clicked on).</StyledPara>


                {/* 3 */}
                <SectionHeader>3. HOW IS MY INFORMATION USED?</SectionHeader>
                <StyledPara>We use personal information for the following purposes or as otherwise described at the time we collect it:</StyledPara>

                <SectionSubHeader>3.1. ACCOUNT SETUP</SectionSubHeader>
                <StyledPara>To get Members started on ChatRadar, we use personal information to:</StyledPara>
                <StyledPara><HighlightedSection>- Populate Member profiles and personalise ChatRadar.</HighlightedSection></StyledPara>

                <SectionSubHeader>3.2. PROVIDE AND IMPROVE THE SERVICES</SectionSubHeader>
                <StyledPara>We use both manual and automated systems to analyse and use your Content (as defined in the <StyledLink to='/terms'>Terms of Service</StyledLink>) and other information, including:</StyledPara>
                <StyledPara><HighlightedSection>- To operate ChatRadar and improve our products and features.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To help you create, publish, view, share, and respond to Content, including highlighting interesting posts.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To highlight businesses, ads, offers, products, and services you may be interested in.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To personalise your experience.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To understand the trends, usage statistics, and demographic data of our Members.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To respond to your support requests and comments.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To test and evaluate potential new products and features on ChatRadar.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To communicate with you about the Service, including by sending announcements, updates, security alerts and support messages via email, notifications, and text messages.</HighlightedSection></StyledPara>
            
                <SectionSubHeader>3.3. MAINTAIN A TRUSTED ENVIRONMENT</SectionSubHeader>
                <StyledPara>We may use your information to help create and maintain a trusted environment on ChatRadar, including:</StyledPara>
                <StyledPara><HighlightedSection>- To detect and prevent fraud, spam, abuse, security incidents, and other harmful, unauthorized, unethical or illegal activity.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To conduct security investigations, audits and risk assessments.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To comply with our legal obligations.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To confirm or authenticate information or identification.</HighlightedSection></StyledPara>
            
                <SectionSubHeader>3.4. ADVERTISING</SectionSubHeader>
                <StyledPara>We use your information to provide and improve advertisements and other commercial offerings both on and off the Services:</StyledPara>
                <StyledPara><HighlightedSection>- To help personalize advertisements based on what we think may interest you.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To provide and measure the effectiveness of personalized advertising, including whether it is interesting to Members and effective for the advertiser.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To improve the personalization of advertisements.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- To send you promotional messages, marketing, advertising and other information that we think may interest you.</HighlightedSection></StyledPara>
                <StyledPara><HighlightedSection>- We may customise advertising offers you see on ChatRadar based on information from our advertisers or vendors, or on your actions, selections, or preferences on ChatRadar.</HighlightedSection></StyledPara>
            
                <SectionSubHeader>3.5. RESEARCH AND DEVELOPMENT</SectionSubHeader>
                <StyledPara>We may use your personal information for research and development purposes. As part of these activities, we may create aggregated, de-identified and/or other anonymous data from personal information we collect. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Services and promote our business.</StyledPara>
            
                <SectionHeader>4. IS MY INFORMATION SHARED WITH THIRD PARTIES?</SectionHeader>
                <StyledPara>We share personal information as follows:</StyledPara>

                <SectionSubHeader>4.1. CONTENT SHARED ON CHATRADAR</SectionSubHeader>
                <StyledPara>ChatRadar is a platform for members to share helpful information with each other. The Content you post on ChatRadar, such as pings (including <StyledExternalLink href="https://en.wikipedia.org/wiki/Location_obfuscation">obfuscated</StyledExternalLink> location data) and your profile information, may be visible to other Members, businesses, or the public, including Visitors (as defined in the <StyledLink to='/terms'>Terms of Service</StyledLink>) and search engines.</StyledPara>
                
                <SectionSubHeader>4.2. LEGAL OBLIGATIONS</SectionSubHeader>
                <StyledPara>We may retain, preserve, or share your Content and personal information with law enforcement, government authorities, and private parties if we have a good-faith belief that it is reasonably necessary to: (a) respond, based on applicable law, to a legal request (e.g. a subpoena, a search warrant, court order, or other request from government or law enforcement); (b) detect, investigate, prevent, and address fraud and other unauthorised, illegal or unethical activity, security, or technical issues; (c) protect our or others’ rights, property, or safety; (d) enforce our <StyledLink to='/terms'>Terms of Service</StyledLink> or any other agreements we have with you; (e) prevent physical injury or other harm to any person or entity, including you and Members of the general public. </StyledPara>
            
                <SectionSubHeader>4.3. ADVERTISING</SectionSubHeader>
                <StyledPara>If you view, click on, or reply to an advertisement or other offer on the Services, we may share your reply and limited Member profile information with the advertiser, and we may log your interaction as part of tracking the overall effectiveness of the advertisement. We may also pre-populate replies to ads you click on with your contact details, but those details are not shared with the advertiser without your consent.</StyledPara>
                <StyledPara>The Services are supported by third party advertising networks. With your consent, our advertising partners use cookies and similar technologies to collect information about our Members and their devices over time across ChatRadar and other online services. Where permissible according to applicable law, we may share certain limited personal information, such as hashed emails and advertising identifiers, with our advertising partners that help serve targeted online advertising to those Members or to similar audiences. Our advertising partners may be able to associate this information with you based on your advertising identifiers or other personal information they have about you. Our advertising partners then use the information they collect to provide our Members with personalized ads on ChatRadar and on different websites and services, and to measure the effectiveness of the ads.</StyledPara>

                <SectionSubHeader>4.4. SERVICE PROVIDERS</SectionSubHeader>
                <StyledPara>We may also share your personal information with service providers and/or data processors to help us operate, provide, improve, understand, customize, support, and market our Services. These service providers and data processors are contractually required to use it only to provide their service to us, and are contractually prohibited from using it for their own purposes. They provide a variety of services, including payment processing, information technology, cybersecurity, fraud prevention, hosting, customer relationship management and support, communications delivery, marketing, advertising, and website and mobile application analytics.</StyledPara>

                <SectionSubHeader>4.5. BUSINESS REORGANISATION</SectionSubHeader>
                <StyledPara>We may need to share your personal information with the relevant participants in transactions (or potential transactions) involving a corporate divestiture, merger, consolidation, acquisition, restructuring, reorganization, or sale or other disposition of some or all of the assets of, or equity interests in, ChatRadar or our affiliates (including, in connection with a bankruptcy or similar proceedings).</StyledPara>

                <SectionSubHeader>4.6 PROFESSIONAL ADVISORS</SectionSubHeader>
                <StyledPara>We may share personal information with our professional advisors, such as lawyers, auditors, bankers and insurers, where necessary in the course of the professional services that they render to us.</StyledPara>

                <SectionHeader>5. YOUR CHOICES</SectionHeader>
                <StyledPara><HighlightedSection>i. - </HighlightedSection>All Pings posted on ChatRadar are public, this includes an <StyledExternalLink href="https://en.wikipedia.org/wiki/Location_obfuscation">obfuscated</StyledExternalLink> version of your location at the time of posting. iOS users can adjust the accuracy of the location provided to our applications in their device settings.</StyledPara>
                <StyledPara><HighlightedSection>ii.  - </HighlightedSection>You can choose whether to show or hide your post history on your personal profile. This won't prevent Pings appearing in searches or being shown at popular at Points of Interest, but will prevent other Members seeing a complete list of your historic Pings. You can always delete a Ping that you no longer wish to be visible.</StyledPara>
                <StyledPara><HighlightedSection>iii. - </HighlightedSection>You can choose to receive push notifications from ChatRadar on your device. If you do not wish to receive push notifications, you can use your device’s settings to turn them off.</StyledPara>

                <SectionHeader>6. CONTROLLING YOUR INFORMATION</SectionHeader>
                <StyledPara>You may exercise your rights to access, correct, erase, object to processing, and request data portability where applicable in the following ways:</StyledPara>
                <StyledPara><HighlightedSection>- Deleting your account.</HighlightedSection> You may delete your account by selecting the option to delete your account in the Settings page. We will delete your information without unreasonable delay after receiving the request except that we may retain archived copies of your information as required by law.</StyledPara>

                <SectionHeader>7. HOW LONG IS MY INFORMATION KEPT?</SectionHeader>
                <StyledPara>We retain your information in our server logs, our databases, and our records for as long as necessary to provide the Services. We may need to retain some of your information for a longer period, such as in order to comply with our legal or regulatory obligations, to resolve disputes or defend against legal claims, or to enforce our <StyledLink to='/terms'>Terms of Service</StyledLink>.</StyledPara>
            
                <SectionHeader>8. HOW IS MY INFORMATION PROTECTED?</SectionHeader>

                <SectionSubHeader>8.1. ACCOUNT SECURITY</SectionSubHeader>
                <StyledPara>ChatRadar uses technical and organizational measures intended to keep your data secure, including using https on our web pages to reduce the risk of unauthorized interception of your communications during your visits to the website and two-factor authentication where appropriate. However, the internet is not a fully secure environment and we cannot guarantee that your personal information won’t be intercepted or improperly accessed. Please ensure that you keep your password safe and do not share it with anyone.</StyledPara>

                <SectionSubHeader>8.2. OTHER SERVICES</SectionSubHeader>
                <StyledPara>When using ChatRadar, you may come across links to websites and services operated by third parties that are not owned or controlled by ChatRadar. This Privacy Policy does not apply to information collected on any third-party site or application that may link to or be accessible from the Services. These third parties have their own privacy policies, which will apply to any personal information they collect. We do not accept any responsibility or liability for such external sites’ privacy and security practices.</StyledPara>

                <SectionHeader>9. CHILDREN</SectionHeader>
                <StyledPara>Children are not allowed to use the Services if they are under 13 years old or not old enough for ChatRadar to process their personal information without parental or age-specific consent where they live. If you believe we might have collected personal information from a child in one of these categories, please contact us at privacy@chatradar.com, and if we learn that we have, we will delete their personal information.</StyledPara>

                <SectionHeader>10. AMENDMENTS</SectionHeader>
                <StyledPara>We may make changes to this Privacy Policy from time to time, so please review it periodically. If we materially change our Privacy Policy, we will take steps to notify you in advance of the change as required by applicable law, for example by emailing you or by posting a notice in your neighborhood feed. If you object to any changes, you may close your account. You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Policy means that the collection, use, and sharing of your personal data is subject to the updated Privacy Policy, as of its effective date.</StyledPara>

                <SectionHeader>11. CONTACT</SectionHeader>
                <StyledPara>The Services are operated and provided by BitSplash Ltd, BitSplash c/o WeWork, 55 Colmore Row, Birmingham, B3 2AA. Email: <StyledExternalLink src="mailto:hello@bitsplash.com">hello@bitsplash.com</StyledExternalLink></StyledPara>

            </StyledBody>
    </StyledWrapper>
  );
};

export default PrivacyPolicy;