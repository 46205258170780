import Home from "./pages/home";
import Terms from "./pages/terms";

import ThirdPartyTerms from "./pages/thirdPartyTerms";
import TrademarkPolicy from "./pages/trademarkPolicy";
import SponsoredPosts from "./pages/SponsoredPosts";
import PageContainer from "./PageContainer";

import PrivacyPolicy from "./pages/privacy";
import CommunityGuidelines from "./pages/community";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Ping from "./pages/ping"
import {StyledRail} from "./shared/rail";
import {NotFound} from "./shared/notFound";
import DeleteAccount from "./pages/deleteAccount";


const router = createBrowserRouter([
  {
    path: "/",
    element: <PageContainer><Home/></PageContainer>,
    errorElement:  <PageContainer><StyledRail><NotFound /></StyledRail></PageContainer>,
  },
  {
    path: "/terms",
    element: <PageContainer><StyledRail><Terms/></StyledRail></PageContainer>,
  },
  {
    path: "/third-party-terms",
    element: <PageContainer><StyledRail><ThirdPartyTerms/></StyledRail></PageContainer>,
  },
  {
    path: "/trademark-policy",
    element: <PageContainer><StyledRail><TrademarkPolicy/></StyledRail></PageContainer>,
  },
  {
    path: "/privacy",
    element: <PageContainer><StyledRail><PrivacyPolicy/></StyledRail></PageContainer>,
  },
  {
    path: "/guidelines",
    element: <PageContainer><StyledRail><CommunityGuidelines/></StyledRail></PageContainer>,
  },
  {
    path: "/delete-account",
    element: <PageContainer><StyledRail><DeleteAccount/></StyledRail></PageContainer>,
  },
  {
    path: "/sponsored-posts",
    element: <PageContainer><StyledRail><SponsoredPosts/></StyledRail></PageContainer>,
  },
  {
    path: "/p/:hash",
    element: <PageContainer><StyledRail><Ping/></StyledRail></PageContainer>,
  },
]);



const Wrapper = () => {
  return (
      <RouterProvider router={router} />
  );
};

export default Wrapper;