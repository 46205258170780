import styled from "styled-components";
import NotFoundImage from "../../assets/404.png"

const StyledNotFoundWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 auto;
    padding: 1rem;
`

const StyledNotFoundImage = styled.img`
    width: 80%;
    max-width: 500px;
`

const NotFound = () => {
    return (
        <StyledNotFoundWrapper>
            <StyledNotFoundImage src={NotFoundImage} alt="404" />
            <p>This page doesn't appear to exist.</p>
        </StyledNotFoundWrapper>
    )
}

export {NotFound};